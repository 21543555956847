import React, { useState, useEffect } from "react"
import {
  Tabs,
  Button,
  Divider,
  Spin,
  Image,
  Menu,
  Dropdown,
  message,
  Modal,
  Space,
} from "antd"
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  UserOutlined,
  FileWordOutlined,
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons"
import AppLayout from "../../components/layout"
import CarTicketDetails from "./CarTicketDetails.js"
import {
  TimeDifference,
  FetchParamValue,
  FetchLocalData,
  FetchParentData,
  IndustryType,
  RAIL_CAR,
} from "../../utils/helper"
import PictureCard from "../../components/upload/PictureCard"
import Estimate from "../myYard/estimate"
import Invoice from "../myYard/invoice"
import Certificate from "../myYard/certificate"
import Performa from "../myYard/performa/Performa"
import API from "../../api"
import { connect, useDispatch } from "react-redux"
import { getTicketDetails, getInYardList } from "../../actions/myYardAction"
import { getDashboardCount } from "../../actions/dashboardAction"
import { Routes } from "../../utils/route"
import { navigate } from "gatsby"
import moment from "moment"
import TicketEmail from "./TicketEmail"
import {
  Equipment,
  FoodTruck,
} from "../../components/EditTicketDetails/editTicketform"
import EditTicket from "../../components/EditTicketDetails/EditTicket"
import { fetchYardList } from "../../actions/yardLocationAction"
const { TabPane } = Tabs
const TicketDetails = props => {
  const { cancelTicket, imageDelete } = API
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [tab, onTab] = useState(0)
  const [estimate, setEstimate] = useState(false)
  const [certificate, setCertificate] = useState(false)
  const [invoice, setInvoice] = useState(false)
  const [proforma, setProforma] = useState(false)
  const [loading, setLoading] = useState(true)
  const [load, setLoad] = useState(true)
  const [postwash, setPostWash] = useState(null)
  const [data, setData] = useState(null)

  const [EditFormvisible, setEditFormVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [equipment, setEquipment] = useState([])
  const [sds, setSds] = useState([])
  const [postImage, setPostImage] = useState([])
  const [prewashService, setPrewashService] = useState([])
  const [indusType, setIndus] = useState(null)
  // const [inspectedBy,setInspectedBy]=useState("");

  const callback = key => {}
  const setTab = v => {
    onTab(v)
  }

  const updatingDocs = (id, resp, name) => {
    if (name === "Post Wash Images") {
      let index = postImage.length
      let allInput = [...postImage]
      allInput[index] = {
        ...allInput[index],
        doc_id: id,
        name: name,
        url: resp,
      }
      setPostImage(allInput)
    } else {
      let index = sds.length
      let allInput = [...sds]
      allInput[index] = {
        ...allInput[index],
        doc_id: id,
        name: name,
        url: resp,
      }
      setSds(allInput)
    }
    // if(name==="Safety Sheets (SDS)"){
    //   resp.map((item)=>{
    //   data.sds.map((sds)=>{
    //     console.log(item,sds)
    //   })
    //   })
    // }
  }

  useEffect(() => {
    const callback = val => {
      if (val === true) {
        setLoading(false)
        props.getTicketDetails(props.id)
      }
    }
    Routes("manager", callback)

    setIndus(IndustryType() === "Food_Grade")
  }, [])

  useEffect(() => {
    if (props.ticketDetailsData.length !== 0) {
      setTicketData(props.ticketDetailsData)
    }
  }, [props.ticketDetailsData])

  const setTicketData = res => {
    setLoad(false)
    const _data = res
    let ticket = {}

    _data.map(item => {
      const assetDetails = (val, id) => {
        return item.asset_txns_details.asset_txns_details.filter(ele => {
          return [val].includes(ele.field_name)
        })[0]?.[id ? "id" : "field_value"]
      }
      const ticketDetails = (val, id) => {
        return item.ticket_details.filter(ele => {
          return [val].includes(ele.field_name)
        })[0]?.[id ? "id" : "field_value"]
      }
      if (item.ticket_details.length !== 0) {
        setPostWash(true)
      } else {
        setPostWash(false)
      }

      let last_known = ""
      let haz
      var str = item.asset_txns_details.last_known
      var res = str?.split("-")
      if (res) {
        if (res[0] === "nonhaz") {
          last_known = str.replace("nonhaz-", "")
          haz = "No"
        } else if (res[0] === "haz") {
          last_known = str.replace("haz-", "")
          haz = "Yes"
        }
      }
      let documentObj = { sds: [], prewash: [], postwash: [] }
      let sds = []
      let prewash = []
      let postwash = []
      const updateImageArr = (name, value, index) => {
        documentObj[name].push({
          doc_id: value.doc_id,
          uid: index,
          name: value.doc_name,
          status: "done",
          url: value.doc_path,
          thumbUrl: value.doc_path,
        })
      }
      let docs = item.asset_txns_details.asset_washout_docs
      docs.length !== 0 &&
        docs.map((item, index) => {
          const file = item.doc_path.split(".")
          const file1 = file[file.length - 1]
          item.fileType = file1
          switch (item.doc_type) {
            case "3510":
              updateImageArr("sds", item, index)
              break
            case "3515":
              updateImageArr("prewash", item, index)
              break
            case "3520":
              updateImageArr("postwash", item, index)
              break
            default:
              break
          }
        })
      const washTime = item.ticket_details.filter((val, ind) => {
        if (val.field_name === "ticket_wash_end_time") {
          return val
        }
      })

      const washStart = item.ticket_details.filter((val, ind) => {
        if (val.field_name === "ticket_wash_start_time") {
          return val
        }
      })

      let diff = ""
      if (washStart.length !== 0 && washTime.length !== 0) {
        diff = TimeDifference(
          washStart[0]?.field_value,
          washTime[0]?.field_value
        )
      }
      let _prewashService = item?.sub_tickets?.filter(
        (item, i) =>
          ![2040, 2050, 2060, 2070].includes(
            item?.sub_service_details?.service_group_id
          )
      )
      const filterData = group => {
        const filteredData = item?.sub_tickets?.filter(
          (item, i) => item?.sub_service_details?.service_group_id === group
        )
        return filteredData
      }
      let _postwashService = {
        consumable: filterData(2040),
        disposal: filterData(2050),
        labor: filterData(2060),
        recycle: filterData(2070),
      }
      setPrewashService(_prewashService)
      const pickupArr = item.asset_txns_details?.asset_txns_details?.filter(
        pick => pick.field_name.includes("pickup")
      )
      const role_id = JSON.parse(localStorage.getItem("data"))

      const equipmentpickedupdate = item?.asset_txns_details?.asset_txns_details.filter(
        obj => {
          return obj.field_value === "equipment_checked_out"
        }
      )
      // console.log("data-vaue",item?.asset_txns_details?.asset_txns_details);
      // console.log("data-vaue",equipmentpickedupdate);
      // const equipmentpickedupdate = item?.asset_txns_details?.asset_txns_details?.map((item)=> item?.field_value)
      // const equipmentdate = item?.asset_txns_details?.asset_txns_details?.map((item)=> item?.created_at )
      // console.log("equipmentpickedupdate.created_at", equipmentpickedupdate.created_at);
      ticket = {
        equipment_pickup_date:
          equipmentpickedupdate?.length > 0
            ? moment(equipmentpickedupdate[0]?.created_at).format("MM/DD/YYYY")
            : "NA",

        pickedUp:
          pickupArr?.length > 0
            ? moment(pickupArr[0]?.created_at).format("MM/DD/YYYY")
            : null,
        inspected_by:
          item?.reading_taken_by_user === null
            ? ""
            : item?.reading_taken_by_user?.username,
        role_id: role_id.role_id,
        ticket_id: item.ticket_id,
        certificate_no: item.certificate_no,
        oxygen_percentage: item.oxygen_percentage,
        lel_percentage: item.lel_percentage,
        h2s_ppm: item.h2s_ppm,
        toxicity_ppm: item.toxicity_ppm,
        ticket_ref_id: item.ticket_ref_id,
        ticket_status_value: item.status,
        ticket_status: FetchParamValue(item.status),
        created_at: item.asset_txns_details.check_in,
        equipment_dropoff_date: moment
          .utc(moment(item.asset_txns_details.check_in).toISOString())
          .format("MM/DD/YYYY"),
        equipment_wash_start_time:
          washStart?.length > 0
            ? moment(
                typeof washStart[0].field_value === "string"
                  ? parseInt(washStart[0].field_value)
                  : washStart[0].field_value
              ).format("MM/DD/YYYY")
            : null,
        equipment_wash_time:
          washTime?.length > 0
            ? moment(
                typeof washTime[0].field_value === "string"
                  ? parseInt(washTime[0].field_value)
                  : washTime[0].field_value
              ).format("MM/DD/YYYY")
            : null,
        customer: item.customer_details.name,
        asset_txns_id: item?.asset_txns_details?.asset_txns_id,
        client_cust_id: item?.customer_details?.clients_cust_id.toString(),
        equipment_no: item?.asset_details?.equipment_no,
        equipment_type_id: {
          parent_id: FetchParentData(item.asset_details?.equipment_type),
          param_value: item?.asset_details?.equipment_type,
        },
        equipment_type: FetchLocalData(
          FetchParentData(item.asset_details?.equipment_type),
          item?.asset_details?.equipment_type
        ),
        location_id: item?.asset_txns_details?.yard_details?.id?.toString(),
        yard_location: item?.asset_txns_details?.yard_details?.name,
        owner: item?.asset_details?.owner,
        wash_duration: diff,
        driver_name: item?.asset_txns_details?.driver_name ?? "",
        job_site: item?.asset_txns_details?.job_site,
        docs: item?.asset_txns_details?.asset_washout_docs,
        haz: haz,
        asset_id: item?.asset_details?.asset_id,
        note: item?.remark,
        sds: documentObj?.sds,
        prewash: documentObj?.prewash,
        postwash: documentObj?.postwash,
        last_known: last_known,
        remarks: item.asset_txns_details?.remark,
        po_id: item?.po_details?.po_id
          ? item?.po_details?.po_id.toString()
          : "",
        po_no: item?.po_details?.po_no,
        services: item?.sub_tickets,
        trucking_carrier: assetDetails("trucking_carrier"),
        trucking_carrier_id: assetDetails("trucking_carrier", true),
        generator: assetDetails("generator"),
        generator_id: assetDetails("generator", true),
        manifest_id: assetDetails("manifest", true),
        manifest: assetDetails("manifest"),
        email: assetDetails("email"),
        number: assetDetails("number"),

        waste_type_id: ticketDetails("waste_type", true),
        waste_type: ticketDetails("waste_type"),
        ticket_wash_start_time: ticketDetails("ticket_wash_start_time"),
        ticket_wash_start_time_id: ticketDetails(
          "ticket_wash_start_time",
          true
        ),
        ticket_wash_end_time: ticketDetails("ticket_wash_end_time"),
        ticket_wash_end_time_id: ticketDetails("ticket_wash_end_time", true),
        gal: ticketDetails("gal"),
        drum: ticketDetails("drum"),
        solvent: ticketDetails("solvent"),
        deodorizer: ticketDetails("deodorizer"),
        ticket_complete_remark: ticketDetails("ticket_complete_remark"),
        ticket_complete_remark_id: ticketDetails(
          "ticket_complete_remark",
          true
        ),
        post_note: ticketDetails("ticket_complete_remark"),
        postwashService: _postwashService,
      }

      if (indusType) {
        const {
          truck_number,
          pump_cleaning,
          kosher_certified,
          wash_type_requested,
          last_known,
          last_known_2,
          last_known_3,
          washtype_details,
          waiting_with_trailer,
          hose_cleaning,
        } = item?.asset_txns_details
        ticket = {
          ...ticket,
          last_known: last_known,
          last_known_2: last_known_2,
          last_known_3: last_known_3,
          wash_type_requested_code: wash_type_requested?.toString(),
          wash_type_requested: washtype_details?.service_name?.param_name,
          waiting_with_trailer: waiting_with_trailer ? "Yes" : "No",
          hose_cleaning: hose_cleaning ? "Yes" : "No",
          kosher_certified: kosher_certified ? "Yes" : "No",
          pump_cleaning: pump_cleaning ? "Yes" : "No",
          truck_number: truck_number,
        }
      }
    })
    let eq = []
    if (IndustryType() !== "Food_Grade") {
      eq = [...Equipment()]
    } else {
      eq = [...FoodTruck()]
    }
    if (ticket.ticket_status_value !== 3220) {
      const filteredData = eq.filter(item => {
        if (
          item.name !== "ticket_wash_start_time" &&
          item.name !== "ticket_wash_end_time" &&
          item.name !== "time_taken"
        ) {
          return item
        }
      })
      setEquipment(filteredData)
    } else {
      setEquipment(eq)
    }
    setData(ticket)
    //debugger

    setSds(ticket.sds.concat(ticket.prewash))
    setPostImage(ticket.postwash)
  }
  const menuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined,
      path: "/",
    },

    {
      title: "Account Setting",
      icon: SettingOutlined,
      path: "/",
    },
    {
      title: "Billing",
      icon: ShopOutlined,
      path: "/",
    },
    {
      title: "Help Center",
      icon: QuestionCircleOutlined,
      path: "/",
    },
  ]
  const onCancelTicket = () => {
    setConfirmLoading(true)
    cancelTicket({ asset_txns_id: data.asset_txns_id })
      .then(res => {
        if (res.data.success) {
          setConfirmLoading(false)
          message.success(res.data.message)
          props.getInYardList(0, "")
          props.getDashboardCount()
          navigate("/myYard")
        }
        setVisible(false)
      })
      .catch(err => {
        setConfirmLoading(false)
        message.error({
          content: `${err?.response?.data.message}`,
          duration: 4,
        })
        setVisible(false)
      })
  }

  useEffect(() => {
    props.fetchYardList()
  }, [])

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        {/* <div className="d-flex">
        <Avatar size={45} src={<UserOutlined style={{fontSize:25,marginLeft:7,marginTop:6}}/>} style={{minWidth:45}} />
        <div className="pl-3">
          <h4 className="mb-0" style={{overflowWrap: 'anywhere'}}>{'userName'}</h4>
          <span className="text-muted">{'companyName'}</span>
        </div>
      </div> */}
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 2} onClick={e => console.log()}>
            <EditTicket
              clients_cust_id={1}
              setVisible={e => setEditFormVisible(e)}
              equipment={equipment}
              setEquipment={setEquipment}
              visible={EditFormvisible}
              postActive={postwash}
              routeId={props.id}
              editData={data}
              buttonColor={"default"}
              custom={{ padding: 0, border: "none", background: "none" }}
              Button={<span className="font-weight-normal">Edit Ticket</span>}
              title="EDIT TICKET"
            />
          </Menu.Item>
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => setVisible(true)}
            className="cancelTicket"
          >
            <span>
              <span>Cancel Ticket</span>
              <span className="font-weight-normal"></span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  const canceledImages = (id, document) => {
    if (document === "Post Wash Images") {
      setPostImage(postImage.filter(item => item.doc_id !== id))
    } else {
      setSds(sds.filter(item => item.doc_id !== id))
    }
  }
  const removeImage = (img, url, data, setData) => {
    // const imgArr=[...data]
    // imgArr.map((_img,i)=>{
    //      if(_img.doc_id===img){
    //          imageDelete(img).then((res)=>{
    //              imgArr.splice(i)
    //          })
    //      }
    //  })
    //  setData(imgArr)
  }
  //localhost:8000/ticketdetails/753

  if (!loading) {
    return (
      <AppLayout>
        {!props.loading ? (
          IndustryType() == RAIL_CAR ? (
            <CarTicketDetails props={props} />
          ) : (
            <>
              <div style={{ position: "relative" }}>
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <h3 style={{ marginBottom: 0 }}>TICKET DETAILS</h3>

                  <Modal
                    title="Confirm"
                    visible={visible}
                    confirmLoading={confirmLoading}
                    onOk={() => onCancelTicket()}
                    onCancel={() => setVisible(false)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>
                      <ExclamationCircleOutlined /> Do you want to cancel this
                      ticket
                    </span>
                  </Modal>
                  {((data?.ticket_status_value === 3225 &&
                    data?.role_id === 99) ||
                    (data?.ticket_status_value !== 3225 &&
                      data?.ticket_status_value !== 3250)) && (
                    <Dropdown
                      placement="bottomRight"
                      overlay={profileMenu}
                      trigger={["click"]}
                    >
                      <Menu
                        className="d-flex align-item-center"
                        mode="horizontal"
                      >
                        <Menu.Item
                          style={{
                            width: 80,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span> Edit </span>
                        </Menu.Item>
                      </Menu>
                    </Dropdown>
                  )}
                </div>
                <div style={{ marginTop: 20 }}>
                  <div className="row">
                    <div className="col-4">
                      <div className="details-card">
                        <div>
                          {" "}
                          <b>CUSTOMER:</b> {data?.customer}
                        </div>
                        <div>
                          <b>TICKET NO:</b> {data?.ticket_ref_id}
                        </div>
                        <div>
                          <b>TICKET STATUS:</b> {data?.ticket_status}
                        </div>
                        {data?.po_no && (
                          <div>
                            <b>PO NUMBER:</b> {data?.po_no}
                          </div>
                        )}
                        <div>
                          <b>CERTIFICATE NUMBER:</b>{" "}
                          {data?.certificate_no ?? "NA"}
                        </div>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="details-card">
                        <div>
                          <b>EQUIPMENT NUMBER:</b> {data?.equipment_no}
                        </div>
                        {indusType && (
                          <div>
                            <b>TRUCK NUMBER:</b> {data?.truck_number ?? "NA"}
                          </div>
                        )}
                        {!indusType && (
                          <div>
                            <b>EQUIPMENT TYPE:</b> {data?.equipment_type}
                          </div>
                        )}
                        <div>
                          <b>EQUIPMENT DROP-OFF DATE:</b>{" "}
                          {data?.equipment_dropoff_date}
                        </div>
                        {data?.equipment_wash_time && (
                          <div>
                            <b>EQUIPMENT WASH DATE:</b>{" "}
                            {data?.equipment_wash_time}
                          </div>
                        )}
                        {data?.equipment_pickup_date && (
                          <div>
                            <b>EQUIPMENT PICKUP DATE:</b>{" "}
                            {data?.equipment_pickup_date}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="details-card">
                        {!indusType && (
                          <div className="col-12">
                            <b>LAST CONTAINED:</b> {data?.last_known}
                          </div>
                        )}
                        <div className="col-12">
                          <b>YARD:</b> {data?.yard_location}
                        </div>
                        {!indusType && (
                          <div className="col-12">
                            <b>FROM/GENERATOR:</b> {data?.generator}
                          </div>
                        )}
                        <div className="col-12">
                          <b>SERVICES:</b>{" "}
                          {prewashService?.length !== 0
                            ? prewashService?.map((item, i) => (
                                <span style={{ marginRight: 7 }}>
                                  {FetchParamValue(item?.service_id)}{" "}
                                  {prewashService?.length - i !== 1 && ","}
                                </span>
                              ))
                            : "NA"}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row" > */}

                  {/* {data?.equipment_wash_start_time && <div className="col-4"><b>EQUIPMENT WASH START DATE:</b> {data?.equipment_wash_start_time}</div>} */}

                  {/* {data?.pickedUp && <div className="col-4"><b>PICKUP DATE:</b> {data?.pickedUp}</div>} */}

                  {/* {indusType&&<>
      <div className="col-4"><b>WASH TYPE REQUESTED:</b> {data?.wash_type_requested}</div>
     <div className="col-4"><b>LAST CONTAINED #1:</b> {data?.last_known}</div>
     <div className="col-4"><b>LAST CONTAINED #2:</b> {data?.last_known_2}</div>
     <div className="col-4"><b>LAST CONTAINED #3:</b> {data?.last_known_3}</div>
     </>}


   
      </div> */}
                </div>
                <div className="details-table-container">
                  <div className="mt-3" style={{ position: "relative" }}>
                    <div
                      className={
                        "tabpane " + (tab === 0 ? "tabpane-active" : "")
                      }
                      onClick={() => setTab(0)}
                    >
                      Prewash Details
                    </div>
                    {postwash && (
                      <div
                        className={
                          "tabpane " + (tab === 1 ? "tabpane-active" : "")
                        }
                        onClick={() => setTab(1)}
                      >
                        Postwash Details
                      </div>
                    )}
                    <div
                      className="link-bar"
                      style={{ left: tab === 0 ? 0 : 168, width: 141 }}
                    ></div>
                  </div>

                  <div></div>
                  <div className="descriptions-view">
                    {tab === 0 ? (
                      <PreWashTable
                        indusType={indusType}
                        data={data}
                        updatingDocs={updatingDocs}
                        removeImage={removeImage}
                        canceledImages={canceledImages}
                        sds={sds}
                      />
                    ) : (
                      postwash && (
                        <PostWashTable
                          data={data}
                          updatingDocs={updatingDocs}
                          removeImage={removeImage}
                          canceledImages={canceledImages}
                          postImage={postImage}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
                onClick={() => setIsDrawerVisible(true)}
              >
                <Button
                  style={{
                    borderColor: "#8B8FAE",
                    background: "#8B8FAE",
                    color: "white",
                  }}
                  onClick={() => navigate("/myYard")}
                >
                  Back
                </Button>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    {tab === 1 ? (
                      postImage.length !== 0 ? (
                        <TicketEmail
                          tab={tab}
                          data={data}
                          customWidth={135}
                          pre={
                            data?.prewash.length !== 0 || data?.sds.length !== 0
                              ? data?.prewash.concat(data?.sds)
                              : ""
                          }
                          post={
                            data?.postwash.length !== 0 ? data?.postwash : ""
                          }
                          postImage={postImage}
                          Button={<span>Email</span>}
                        />
                      ) : (
                        ""
                      )
                    ) : sds.length !== 0 ? (
                      <TicketEmail
                        tab={tab}
                        data={data}
                        customWidth={135}
                        pre={
                          data?.prewash.length !== 0 || data?.sds.length !== 0
                            ? data?.prewash.concat(data?.sds)
                            : ""
                        }
                        post={data?.postwash.length !== 0 ? data?.postwash : ""}
                        sds={sds}
                        // pre={pre}
                        Button={<span>Send to Email</span>}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  {data?.ticket_status_value !== 3210 &&
                    data?.ticket_status_value !== 3205 &&
                    data?.ticket_status_value !== 3250 && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {/* <Estimate visible={estimate} setVisible={(e) => setEstimate(e)} 
  data={data}
{...props} Button={<span>Estimate</span>} title="Estimate" /> */}
                        {data?.ticket_status_value !== 3215 && (
                          <>
                            <div style={{ marginLeft: 20 }}>
                              <Certificate
                                customWidth={135}
                                visible={certificate}
                                setVisible={e => setCertificate(e)}
                                data={data}
                                {...props}
                                Button={<span>Certificate</span>}
                                title="Certificate Of Washout"
                              />
                            </div>
                            <div style={{ marginLeft: 20 }}>
                              {" "}
                              <Performa
                                customWidth={193}
                                component="ticketDetails"
                                visible={proforma}
                                setVisible={e => setProforma(e)}
                                data={data}
                                {...props}
                                Button={<span>Estimate | Chargesheet</span>}
                                title="Estimate | Chargesheet"
                              />
                            </div>
                          </>
                        )}

                        {data?.ticket_status_value === 3225 && (
                          <div style={{ marginLeft: 20 }}>
                            {" "}
                            <Invoice
                              customWidth={135}
                              visible={invoice}
                              setVisible={e => setInvoice(e)}
                              data={data}
                              {...props}
                              Button={<span>Final Invoice</span>}
                              title="Final Invoice"
                            />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </>
          )
        ) : (
          <div className="spinner-main">
            <Spin />
          </div>
        )}
        <style>{`
  .details-card{
    background: white;
    border-radius: 10px;
    padding: 22px;
    min-height: 156px;
  }
  .details-table-container{
    padding: 3px 18px 18px 18px;
    background: white;
    margin-top: 22px;
    border-radius: 10px;
  }
  .tabpane{
    display: inline-block;
    height: 100%;
    cursor:pointer;
    margin-right: 1.25rem;
    padding: 0.75rem 1.25rem;
    box-sizing: border-box;
    position: relative;
  }
  .tabpane:hover{
    color: #3e79f7;
  }
  .tabpane-active{
    color: #e99260;
    font-weight:500;
  }
  .link-bar{
    position: absolute;
    background: #e99260;
    pointer-events: none;
    bottom: 0;
    transition: width 0.3s, left 0.3s, right 0.3s;
    height: 2px;
    opacity: 1;
  }
  .descriptions-view{
    border: 1px solid #edf2f9;
    width: 100%;
    overflow: hidden;
  }
  .descriptions-row{
    border-bottom: 1px solid #edf2f9;
  }
  .cancelTicket:hover{
      color:red;
  }
  .item-label{
    width: 21%;
    background-color: #f5fbff;
    padding: 16px 24px;
    border-right: 1px solid #edf2f9;
    color: #1a3353;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    text-align: start;
  }
  .item-contant{
    width:30%;
    padding: 16px 24px;
    border-right: 1px solid #edf2f9;
    display: table-cell;
    flex: 1;
    color: #455560;
    font-size: 14px;
    line-height: 1.5;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  
  `}</style>
      </AppLayout>
    )
  } else {
    return (
      <div className="spinner-main">
        <Spin />
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ticketDetailsData: state.myYard.ticketDetails,
  loading: state.myYard.loading,
})

export default connect(mapStateToProps, {
  getTicketDetails,
  getInYardList,
  getDashboardCount,
  fetchYardList,
})(TicketDetails)

const PreWashTable = ({
  data,
  indusType,
  removeImage,
  updatingDocs,
  canceledImages,
  sds,
}) => {
  const presds =
    sds &&
    sds.filter(item => {
      return item.name === "Pre Wash Images" ? item : ""
    })
  const sdspre =
    sds &&
    sds.filter(item => {
      return item.name !== "Pre Wash Images" ? item : ""
    })

  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr className="descriptions-row">
          <th className="item-label">OWNER</th>
          <td className="item-contant">{data?.owner}</td>
          <th className="item-label">PHONE NO.</th>
          <td className="item-contant" colSpan={3}>
            {data?.number}
          </td>
        </tr>
        {!indusType ? (
          <tr className="descriptions-row">
            <th className="item-label">JOBSITE</th>
            <td className="item-contant">{data?.job_site}</td>
            <th className="item-label">EMAIL</th>
            <td className="item-contant">{data?.email}</td>
          </tr>
        ) : (
          <tr className="descriptions-row">
            <th className="item-label">KOSHER CERTIFIED</th>
            <td className="item-contant">{data?.kosher_certified}</td>
            <th className="item-label">Email</th>
            <td className="item-contant">{data?.email}</td>
          </tr>
        )}
        {indusType && (
          <tr className="descriptions-row">
            <th className="item-label">WAITING WITH TRAILER</th>
            <td className="item-contant">{data?.waiting_with_trailer}</td>
            <th className="item-label">HOSE CLEANING</th>
            <td className="item-contant">{data?.hose_cleaning}</td>
          </tr>
        )}
        <tr className="descriptions-row">
          <th className="item-label">PO NO.</th>
          <td className="item-contant">{data?.po_no}</td>
          {indusType && <th className="item-label">PUMP CLEANING</th>}
          {indusType && <td className="item-contant">{data?.pump_cleaning}</td>}
          {!indusType && <th className="item-label">TRUCK CARRIER</th>}
          {!indusType && (
            <td className="item-contant">{data?.trucking_carrier}</td>
          )}
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">DRIVER</th>
          <td className="item-contant">{data?.driver_name}</td>
          {!indusType && <th className="item-label">MANIFEST</th>}
          {!indusType && <td className="item-contant">{data?.manifest}</td>}
        </tr>

        <tr className="descriptions-row">
          <th className="item-label">DRIVER</th>
          <td className="item-contant">{data?.driver_name}</td>
          {!indusType && <th className="item-label">INSPECTED BY</th>}
          {!indusType && <td className="item-contant">{data?.inspected_by}</td>}

          {/* <td className='item-contant'>{data?.xyz}</td> */}
        </tr>

        {!indusType && (
          <tr className="descriptions-row">
            <th className="item-label">HAZARDOUS</th>
            <td className="item-contant">{data?.haz}</td>
            <th className="item-label"></th>
            <td className="item-contant"></td>
          </tr>
        )}
        <tr className="descriptions-row">
          <th className="item-label">REMARKS</th>
          <td className="item-contant" colSpan={5}>
            {data?.remarks}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">PRE-WASH NOTE</th>
          <td className="item-contant" colSpan={5}>
            {data?.note}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">Oxygen %</th>
          <td className="item-contant" colSpan={5}>
            {data?.oxygen_percentage}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">Toxicity PPM</th>
          <td className="item-contant" colSpan={5}>
            {data?.toxicity_ppm}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">H2S PPM</th>
          <td className="item-contant" colSpan={5}>
            {data?.h2s_ppm}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">LEL %</th>
          <td className="item-contant" colSpan={5}>
            {data?.lel_percentage}
          </td>
        </tr>

        <tr className="descriptions-row">
          <th className="item-label">SDS</th>
          <td className="item-contant" colSpan={5}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "10px",
              }}
            >
              <PictureCard
                doc_name={"Safety Sheets (SDS)"}
                ticketUpdate={true}
                asset_txns_id={data?.asset_txns_id}
                style={{ marginTop: 20 }}
                responseTicket={updatingDocs}
                data={sdspre}
                removeImage={removeImage}
                canceledImages={canceledImages}
                hide={
                  data?.ticket_status_value !== 3225 &&
                  data?.ticket_status_value !== 3250
                }
              />
              {/* {data?.sds.length!==0?data?.sds.map((item,i)=><FileDisplay item={item}/>):'NA'} */}
              {/* <div>
     {data?.sds.length!==0?<TicketEmail data={data?.sds} Button={<span>Share</span>} title="SDS Wash Images" equipment_no={data?.equipment_no}/>:""}
     </div> */}
            </div>
          </td>
        </tr>

        <tr className="descriptions-row">
          <th className="item-label">PRE WASH IMAGES</th>
          <td className="item-contant" colSpan={5}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "10px",
              }}
            >
              <PictureCard
                doc_name={"Pre Wash Images"}
                style={{ marginTop: 20 }}
                ticketUpdate={true}
                asset_txns_id={data?.asset_txns_id}
                responseTicket={updatingDocs}
                data={presds}
                removeImage={removeImage}
                canceledImages={canceledImages}
                hide={
                  data?.ticket_status_value !== 3225 &&
                  data?.ticket_status_value !== 3250
                }
              />
              {/* {data?.prewash.length!==0?data?.prewash.map((item,i)=><FileDisplay item={item}/>):'NA'} */}
              {/* <div>
     {data?.prewash.length!==0?<TicketEmail data={data?.prewash} Button={<span>Share</span>} title="Pre Wash Images" equipment_no={data?.equipment_no}/>:""}
     </div> */}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
const PostWashTable = ({
  data,
  removeImage,
  updatingDocs,
  canceledImages,
  postImage,
}) => {
  return (
    <table style={{ width: "100%" }}>
      <tbody style={{ width: "100%" }}>
        <tr className="descriptions-row">
          <th className="item-label">TIME TAKEN</th>
          <td className="item-contant">{data.wash_duration}</td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">WASTE TYPE</th>
          <td className="item-contant">{data?.waste_type}</td>
        </tr>
        {data?.postwashService && (
          <>
            {data?.postwashService?.consumable?.length !== 0 && (
              <ServiceItem
                name="CONSUMABLE SERVICE"
                data={data?.postwashService?.consumable}
              />
            )}
            {data?.postwashService?.disposal?.length !== 0 && (
              <ServiceItem
                name="DISPOSAL SERVICE"
                data={data?.postwashService?.disposal}
              />
            )}
            {data?.postwashService?.labor?.length !== 0 && (
              <ServiceItem
                name="LABOR SERVICE"
                data={data?.postwashService?.labor}
              />
            )}
            {data?.postwashService?.recycle?.length !== 0 && (
              <ServiceItem
                name="RECYCLE SERVICE"
                data={data?.postwashService?.recycle}
              />
            )}
          </>
        )}
        {/* <tr className="descriptions-row">
     <th className="item-label">AMOUNT TO DIGOUT</th> 
     <td className="item-contant" colSpan={3}>
     GAL: {data?.gal} <br/>
     DRUM: {data?.drum} <br/>
     SOLVENT: {data?.solvent} <br/>
     DEODORIZER: {data?.deodorizer} 
     </td>
    </tr> */}
        <tr className="descriptions-row">
          <th className="item-label">NOTES</th>
          <td className="item-contant" colSpan={3}>
            {data?.ticket_complete_remark}
          </td>
        </tr>
        <tr className="descriptions-row">
          <th className="item-label">POST WASH IMAGES</th>
          <td className="item-contant" colSpan={5}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "10px",
              }}
            >
              <PictureCard
                doc_name={"Post Wash Images"}
                style={{ marginTop: 20 }}
                ticketUpdate={true}
                asset_txns_id={data?.asset_txns_id}
                responseTicket={updatingDocs}
                data={postImage}
                removeImage={removeImage}
                canceledImages={canceledImages}
                hide={
                  data?.ticket_status_value !== 3225 &&
                  data?.ticket_status_value !== 3250
                }
              />
              {/* {data?.postwash.length!==0?data?.postwash.map((item,i)=><FileDisplay item={item}/>):'NA'} */}

              {/* <div>
     {data?.postwash.length !== 0 ? <TicketEmail data={data?.postwash} Button={<span>Share</span>} title="Post Wash Images" equipment_no={data?.equipment_no}/>:""}
     </div> */}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const ServiceItem = ({ data, name }) => {
  return (
    <tr className="descriptions-row">
      <th className="item-label">{name}</th>
      <td className="item-contant" colSpan={3}>
        <tbody style={{ width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {data?.length !== 0 ? (
              data?.map((item, i) => {
                return (
                  <span>
                    {i + 1}. {FetchParamValue(item?.service_id)} -{" "}
                    {item.quantity}
                  </span>
                )
              })
            ) : (
              <div>NA</div>
            )}
          </div>
        </tbody>
      </td>
    </tr>
  )
}

const FileDisplay = ({ item, type }) => {
  switch (item.fileType) {
    case "png":
    case "jpg":
    case "jpeg":
    case "JPEG":
    case "PNG":
    case "JPG":
      return (
        <span style={{ marginRight: 15 }}>
          <Image
            width={100}
            src={item.doc_path}
            preview={{
              src: item.doc_path,
            }}
          />
        </span>
      )
    case "pptx":
      return (
        <a href={item.doc_path}>
          <FilePdfOutlined style={{ fontSize: 53 }} />
        </a>
      )
    case "xls":
      return (
        <a href={item.doc_path}>
          <FileExcelOutlined style={{ fontSize: 53 }} />
        </a>
      )
    case "docx":
      return (
        <a href={item.doc_path}>
          <FileWordOutlined style={{ fontSize: 53 }} />
        </a>
      )
    default:
      return <div></div>
    // break;
  }
}
